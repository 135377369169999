<template>
    <div>
        <RunVerificationModal :open="showVerificationModal" @close="closeVerificationModal" />
        <ValidationObserver ref="insurance_details_and_verification">
            <form @submit.prevent="submit">
                <div title="Insurance Details" class="">
                    <h3>Insurance Details</h3>
                    <!-- TODO convert dropdown to use dynamic options -->
                    <div class="flex space-between wrap">
                        <DropDown
                            class="flex-1 right-15"
                            label="Insurance Type"
                            :options="planTypeOptions"
                            name="insurance_type"
                            :id="'insuranceTypeId' + index"
                            :disabled="!isEditMode"
                            v-model="insuranceDetailsAndVerifi.insurance_type"
                        />
                        <div class="flex-2">
<!--                            <label>Insurance Payer</label>-->
<!--                            <Multiselect-->
<!--                                v-model="insuranceDetailsAndVerifi.insurance_payer"-->
<!--                                :options="insurance_payers_list"-->
<!--                                @select="emitCurrentlySelectedPayer"-->
<!--                                track-by="id"-->
<!--                                :options-limit="1000"-->
<!--                                label="payer_label"-->
<!--                            />-->
                            <label>Insurance Payer</label>
                            <Multiselect
                                v-model="insuranceDetailsAndVerifi.insurance_payer"
                                :options="insurance_payers_list"
                                @select="emitCurrentlySelectedPayer"
                                track-by="id"
                                :options-limit="1000"
                                label="payer_label"
                            >
                                <template slot='singleLabel' slot-scope="{ option }">
                                    <span>{{ option.payer_label }}</span>
                                    <span class='material-icons blue pad-l-10' v-if='Boolean(option.checked)'>check_circle</span>
                                </template>
                                <template slot="option" slot-scope="option">
                                    <span class=''>{{ option.option.payer_label }}</span>
                                    <span class='material-icons blue pad-l-10' v-if='option.option.checked'>check_circle</span>
                                </template>
                            </Multiselect>
                        </div>
                    </div>
                    <div class="flex colx3 wrap space-between">
                        <div class="colspan-x1">
                            <label>Eligibility Insurance Payer VOB</label>
                            <Multiselect
                                v-model="insuranceDetailsAndVerifi.insurance_vob"
                                :options="insurance_payers_vob_list"
                                @select="emitCurrentlySelectedPayerVob"
                                track-by="id"
                                :options-limit="1000"
                                label="payer_label"
                            />
                        </div>

                        <DropDown
                            label="Plan Type"
                            :options="insuranceTypeOptions"
                            name="plan_type"
                            :id="'planTypeId' + index"
                            :disabled="!isEditMode"
                            v-model="insuranceDetailsAndVerifi.plan_type"
                        />
                        <Input
                            type="text"
                            label="Membership ID"
                            name="member_id"
                            :id="'memberId' + index"
                            :disabled="!isEditMode"
                            v-model="insuranceDetailsAndVerifi.member_id"
                        />
                        <Input
                            type="text"
                            label="Group ID"
                            name="insurance_group_id"
                            :id="'insuranceGroupId' + index"
                            :disabled="!isEditMode"
                            v-model="insuranceDetailsAndVerifi.group_id"
                        />
                        <Input
                        type="text"
                            label="Name of Insured Person"
                            name="name_insured_person"
                            :id="'nameInsuredPersonId' + index"
                            :disabled="!isEditMode"
                            v-model="insuranceDetailsAndVerifi.name_insured"
                        />
                        <!-- TODO convert dropdown to use dynamic options -->
                        <DropDown
                            label="Relationship to Insured"
                            :options="relationToInsuredOptions"
                            name="relation_to_person"
                            :id="'relationToPersonId' + index"
                            :disabled="!isEditMode"
                            v-model="insuranceDetailsAndVerifi.relationship"
                        />
                        <Input
                            label="Date of Birth of Insured"
                            type="date"
                            name="dob_person"
                            :id="'dobPersonId' + index"
                            v-model="insuranceDetailsAndVerifi.dob_insured"
                        />
                    </div>
                    <div class="align-right">
                        <button class='secondary' type="button" @click='removeCard(index)' v-if="index > 0 && !insurance.veriId">
                            <span class='material-icons'>delete_outline</span>
                        </button>
                        <!--                        Add Back in later -->
                        <!--                        <button type="button" id="insurance-details-cancel" @click="cancelDetail()">Cancel</button>-->
                        <button type="submit" id="insurance-details-button">Save</button>
                    </div>
                </div>

                <div title="Verification of Eligibility and Benefits" class="verification-benefits" v-if='this.insurance.cardId'>
                    <h3 class="margin-0">Verification of Eligibility and Benefits</h3>
                    <div class="bottom-20 fullwidth">
                        Choose Run Verification to automatically check benefits through the clearinghouse. Or, choose
                        Manual Verification if you prefer to contact the insurance company to verify the benefits
                        yourself.
                    </div>
                    <div class="bottom-20 fullwidth">
                        <button class="purple-bg" type="button" @click="showVerificationModal = true">
                            Run Verification
                        </button>
                        <!--                        <button-->
                        <!--                            class="purple-bg"-->
                        <!--                            type="button"-->
                        <!--                            @click="showVerificationFields = !showVerificationFields"-->
                        <!--                        >-->
                        <!--                            Manual Verification-->
                        <!--                        </button>-->
                    </div>
                    <div class="flex colx3 space-between wrap">
                        <span>
                            <label :for="'dateVeriCompleted' + index">Date Verification Completed</label>
                            <input
                                label="Date Verification Completed"
                                type="date"
                                name="date_veri_complete"
                                :id="'dateVeriCompleted' + index"
                                v-model="date_veri_completed"
                                :disabled="true"
                            />
                        </span>
                        <Input
                            type="text"
                            label="Name of Representative"
                            name="name_of_rep"
                            :disabled="!isEditMode"
                            v-if="showVerificationFields"
                            v-model="insuranceDetailsAndVerifi.name_of_rep"
                        />
                        <Input
                            type="text"
                            label="Reference Number for Call"
                            name="ref_num_for_call"
                            :disabled="!isEditMode"
                            v-if="showVerificationFields"
                            v-model="insuranceDetailsAndVerifi.ref_num_for_call"
                        />
                        <DropDown
                            :options="isPolicyCurrentlyActiveOptions"
                            name="is_pol_active"
                            label="Is Policy Currently Active"
                            :disabled="!isEditMode"
                            v-if="showVerificationFields"
                            v-model="insuranceDetailsAndVerifi.policy_active"
                        />
                        <span v-if="showVerificationFields">
                            <label :for="'policyStartDate' + index">Policy Start Date</label>
                            <input
                                type="date"
                                name="policy_start_date"
                                label="Policy Start Date"
                                :disabled="!isEditMode"
                                :id="'policyStartDate' + index"
                                v-model="insuranceDetailsAndVerifi.start_date_policy"
                            />
                        </span>
                        <span v-if="showVerificationFields">
                            <label :for="'policyEndDate' + index">Policy End Date</label>
                            <input
                                type="date"
                                name="policy_end_date"
                                label="Policy End Date"
                                :disabled="!isEditMode"
                                :id="'policyEndDate' + index"
                                v-model="insuranceDetailsAndVerifi.end_date_policy"
                            />
                        </span>
                        <DropDown
                            :options="isThereDeductibleOptions"
                            name="is_deductible"
                            label="Is there a deductible?"
                            :disabled="!isEditMode"
                            v-model="insuranceDetailsAndVerifi.is_there_deductible"
                            :id="'isDeductible' + index"
                            v-if="showVerificationFields"
                        />
                        <!-- <Input
                            name="is_deductible"
                            label="Is There a Dedudctible?"
                            :disabled="!isEditMode"
                            v-model="insuranceDetailsAndVerifi.is_there_deductible"
                            v-if="showVerificationFields"
                            :id="'isDeductible' + index"
                        /> -->
                        <!-- TODO convert dropdown to use dynamic options -->
                        <DropDown
                            :options="deductibleMetOptions"
                            name="deduductible_met"
                            label="Has Deductible Been Met?"
                            :disabled="!isEditMode"
                            v-model="insuranceDetailsAndVerifi.deductible_met"
                            v-if="showVerificationFields"
                            :id="'deductibleMet' + index"
                        />
                        <Input
                            name="deductible_remain"
                            type="text"
                            label="Deductible Amount Remaining?"
                            :disabled="!isEditMode"
                            v-model="insuranceDetailsAndVerifi.deductible_remain"
                            v-if="showVerificationFields"
                            :id="'deductibleRemain' + index"
                        />
                        <Input
                            type="text"
                            name="visit_per_year"
                            label="Visits Allowed Per Year?"
                            :disabled="!isEditMode"
                            v-model="insuranceDetailsAndVerifi.visits_allowed"
                            v-if="showVerificationFields"
                            :id="'visitAllowed' + index"
                        />
                        <Input
                            type="text"
                            name="visits_used"
                            label="How Many Visits Used?"
                            :disabled="!isEditMode"
                            v-model="insuranceDetailsAndVerifi.visits_used"
                            v-if="showVerificationFields"
                            :id="'visitUsed' + index"
                        />
                        <Input
                            type="text"
                            name="visits_remain"
                            label="How Many Visits are Remaining?"
                            :disabled="!isEditMode"
                            v-model="insuranceDetailsAndVerifi.visits_remain"
                            v-if="showVerificationFields"
                            :id="'visitRemain' + index"
                        />
                        <Input
                            type="text"
                            name="co_pay"
                            label="Copay"
                            :disabled="!isEditMode"
                            :id="'coPay' + index"
                            v-if="showVerificationFields"
                            v-model="insuranceDetailsAndVerifi.co_pay"
                        />
                        <Input
                            type="text"
                            name="co_insurance"
                            label="Coinsurance"
                            :disabled="!isEditMode"
                            :id="'coInsurance' + index"
                            v-if="showVerificationFields"
                            v-model="insuranceDetailsAndVerifi.co_insurance"
                        />
                        <!-- TODO convert dropdown to use dynamic options -->
                        <DropDown
                            :options="isProvideInNetworkOptions"
                            name="is_provider_in_network"
                            label="Is Provider In Network?"
                            :disabled="!isEditMode"
                            :id="'isProvideInNetwork' + index"
                            v-if="showVerificationFields"
                            v-model="insuranceDetailsAndVerifi.is_provide_in_network"
                        />
                        <Input
                            type="text"
                            name="out_of_network_benefits"
                            label="Out of Network Benefits?"
                            :disabled="!isEditMode"
                            :id="'outOfNetworkBenefits' + index"
                            v-if="showVerificationFields"
                            v-model="insuranceDetailsAndVerifi.out_of_network_benefits"
                        />
                        <!-- TODO convert dropdown to use dynamic options -->
                        <DropDown
                            :options="isAuthRequiredOptions"
                            name="preauth_require"
                            label="Preauthorization Required"
                            :id="'preauthRequired' + index"
                            v-if="showVerificationFields"
                            v-model="insuranceDetailsAndVerifi.preauth_require"
                        />
                        <Input
                            type="text"
                            name="authorization_num"
                            label="Authorization Number"
                            :disabled="!isEditMode"
                            :id="'authorizationNum' + index"
                            v-if="showVerificationFields"
                            v-model="insuranceDetailsAndVerifi.auth_num"
                        />
                        <span v-if="showVerificationFields">
                            <label :for="'startDateAuthId' + index">Start Date of Authorization</label>
                            <input
                                label="Start Date of Authorization"
                                type="date"
                                name="start_date_auth"
                                :id="'startDateAuthId' + index"
                                v-model="insuranceDetailsAndVerifi.start_date_auth"
                            />
                        </span>
                        <span v-if="showVerificationFields">
                            <label :for="'endDateAuthId' + index">End Date of Authorization</label>
                            <input
                                label="End Date of Authorization"
                                type="date"
                                name="end_date_auth"
                                :id="'endDateAuthId' + index"
                                v-model="insuranceDetailsAndVerifi.end_date_auth"
                            />
                        </span>
                        <!-- TODO convert dropdown to use dynamic options -->
                        <DropDown
                            :options="isReferralRequiredOptions"
                            name="referral_require"
                            label="Referral Required"
                            :disabled="!isEditMode"
                            :id="'referralRequired' + index"
                            v-if="showVerificationFields"
                            v-model="insuranceDetailsAndVerifi.ref_required"
                        />
                        <Input
                            type="text"
                            name="service_treatment_exclude"
                            label="Any Services or Treatments Excluded?"
                            :disabled="!isEditMode"
                            :id="'serviceTreatmentExclude' + index"
                            class="fullwidth"
                            v-if="showVerificationFields"
                            v-model="insuranceDetailsAndVerifi.service_or_treatment_exclude"
                        />
                        <DropDown
                            name="limit_exclusion_doc_requirements"
                            :options="limitExcludeDocumentRequirementOptions"
                            label="Any Limitations, Exclusions or Document Requirements?"
                            :disabled="!isEditMode"
                            :id="'limitExclusionDocRequirements' + index"
                            v-if="showVerificationFields"
                            v-model="insuranceDetailsAndVerifi.limit_exclude_docum_requirement"
                        />
                        <!-- <Input
                            name="limit_exclusion_doc_requirements"
                            label="Any Limitations, Exclusions or Document Requirements?"
                            :disabled="!isEditMode"
                            :id="'limitExclusionDocRequirements' + index"
                            class="fullwidth"
                            v-if="showVerificationFields"
                            v-model="insuranceDetailsAndVerifi.limit_exclude_docum_requirement"
                        /> -->
                        <span class="fullwidth" v-if="showVerificationFields">
                            <label for="">Notes</label>
                            <textarea
                                name="notes"
                                label="Notes"
                                :id="'insuranceNotes' + index"
                                :disabled="!isEditMode"
                                v-model="insuranceDetailsAndVerifi.notes"
                            />
                        </span>
                    </div>
                </div>
                <div class="align-right" v-if='insurance.cardId'>
                    <button class='secondary' type='button' @click='removeCard(index)' v-if="index > 0">
                        <span class='material-icons'>delete_outline</span>
                    </button>
                    <button type="submit" v-if="showVerificationFields" id="insurance-verification-button">Save</button>
                </div>
            </form>
        </ValidationObserver>
    </div>
</template>

<script>
    import Input from '@/components/general/validatedInputs/Input.vue';
    import DropDown from '@/components/general/validatedInputs/Dropdown.vue';
    import { insurance, insurancePayers } from '@/util/apiRequests';
    import RunVerificationModal from '@/components/general/modals/RunVerificationModal';
    import dayjs from '@/util/dayjs';

    import {
        insuranceTypeOptions,
        relationToInsuredOptions,
        planTypeOptions,
        deductibleMetOptions,
        hasDeductibleBeenMetOptions,
        isPolicyCurrentlyActiveOptions,
        isProvideInNetworkOptions,
        outOfNetworkBenefitsOptions,
        isAuthRequiredOptions,
        isReferralRequiredOptions,
        isThereDeductibleOptions,
        limitExcludeDocumentRequirementOptions
    } from '@/util/options';
    import { ValidationObserver } from 'vee-validate';

    export default {
        components: { Input, DropDown, ValidationObserver, RunVerificationModal },
        name: 'InsuranceDetailsAndVerification',
        props: {
            insurance: {
                type: Object
            },
            index: {},
            removeCard: {
            },
        },
        data() {
            return {
                showVerificationModal: false,
                tempInsuranceDetailsAndVerifi: {},
                date_veri_completed: '',
                insuranceDetailsAndVerifi: {
                    insurance_type: '',
                    plan_type: '',
                    member_id: '',
                    group_id: '',
                    name_insured: '',
                    relationship: '',
                    dob_insured: '',
                    ins_num: '',
                    group_num: '',
                    service_code: '',
                    name_of_rep: '',
                    ref_num_for_call: '',
                    policy_active: '',
                    deductible_met: '',
                    is_there_deductible: '',
                    deductible_remain: '',
                    visits_allowed: '',
                    visits_used: '',
                    visits_remain: '',
                    co_pay: '',
                    co_insurance: '',
                    is_provide_in_network: '',
                    out_of_network_benefits: '',
                    preauth_require: '',
                    auth_num: '',
                    start_date_auth: '',
                    end_date_auth: '',
                    ref_required: '',
                    service_or_treatment_exclude: '',
                    limit_exclude_docum_requirement: '',
                    notes: '',
                    start_date_policy: '',
                    end_date_policy: '',
                    insurance_payer: {},
                    insurance_payers_vob_id: 0,
                },
                insuranceTypeOptions,
                relationToInsuredOptions,
                planTypeOptions,
                deductibleMetOptions,
                hasDeductibleBeenMetOptions,
                isPolicyCurrentlyActiveOptions,
                isProvideInNetworkOptions,
                outOfNetworkBenefitsOptions,
                isAuthRequiredOptions,
                isReferralRequiredOptions,
                isThereDeductibleOptions,
                limitExcludeDocumentRequirementOptions,
                isEditMode: true,
                showVerificationFields: true,
                insurance_payers_setting: '',
                insurance_payers_list: [],
                insurance_payers_vob_list: [],
                narrowed_insurance_payers_list: []
            };
        },

        methods: {
            closeVerificationModal(action) {
                if (action == 'cancel') {
                    this.showVerificationModal = false;
                } else if (action == 'proceed') {
                    this.showVerificationModal = false;
                    this.verify_card_check_eligibilities();
                }
            },
            emitCurrentlySelectedPayer(val) {
                this.insurance.insurance_payer_id = val.payer_id;
                this.$emit('assignId', this.insurance, this.insurance?.cardId, this.insurance?.veriId);
            },
            emitCurrentlySelectedPayerVob(val) {
                this.insurance.insurance_payers_vob_id = parseInt( val.id )
                this.$emit('assign_vob_id', this.insurance, this.insurance?.cardId, this.insurance?.veriId);
            },
            async get_insurance_payers() {
                let level_id = this.$store.state.user.company_id;
                const body = {
                    criteria: {
                        page: { num_per_page: '100000', page_num: '1' },
                    }
                }
                const res = await this.$api.post(insurancePayers.getList(), body);
                const my_payers = res.data?.rows;
                if(my_payers.length){
                        this.insurance_payers_setting = my_payers
                        this.insurance_payers_list = my_payers.map(function(e) {
                            e.payer_label = e.payer_id + ' : ' + e.payer_name;
                            return e;
                        });
                        this.insurance_payers_list = this.insurance_payers_list.filter((e) => {
                            if (e.payer_label == 'undefined : undefined') {
                                return false;
                            }
                            return true;
                        });
                }
                const res1 = await this.$api.get(`/settings/setting/insurance_payers/company/${level_id}`);
                if (res) {
                    const my_payers = res1.data.value;

                    this.narrowed_insurance_payers_list = JSON.parse(my_payers);
                    //map over insurance_payers_list and add a checkmark to the list if it is in the narrowed_insurance_payers_list also each item is an object
                    this.insurance_payers_list = this.insurance_payers_list.map(e => {
                       if(this.narrowed_insurance_payers_list.some(i => i.id === e.id)){
                            e.checked = true;
                       }
                        return e;
                    });
                }
            },
            async get_insurance_payers_vob() {
                const body = {
                    criteria: {
                        page: { num_per_page: '10000', page_num: 1 }
                    }
                };
                const res = await this.$api.post(`/insurance_payers/vob`, body);

                this.insurance_payers_vob_list = res.data.rows.map(function(e) {
                    e.payer_label = e.payer_id + ' : ' + e.payer_name
                    return e
                })
            },
            async submit(e) {
                //Get the data (Any Data)
                //Data will return a new id
                //Saving if new will create a new row and emit the id back up so that the rest of the components can get the information.
                try {
                    //I'm doing this because, a refactor will take too long...
                    let whichSave;
                    if(e.submitter.id == 'insurance-details-button'){
                        whichSave = 'insurance_card';
                    }
                    if(e.submitter.id == 'insurance-verification-button'){
                        whichSave = 'insurance_verification'
                    }
                    let temp = this.insuranceDetailsAndVerifi;
                    temp.insurance_payers_id = temp.insurance_payer?.id;
                    temp.insurance_payers_vob_id = temp.insurance_vob?.id;
                    delete temp?.insurance_vob
                    let copyOfInsurancePayer = null;
                    try {
                        copyOfInsurancePayer = JSON.parse(JSON.stringify(temp.insurance_payer));
                    } catch (error) {
                        //
                    }

                    //the one below is for contact information - i  know the naming sucks plz bare with it.

                    delete temp.insurance_payer;
                    temp.cardId = this.insurance.cardId;
                    temp.veriId = this.insurance.veriId;
                    temp.client_id = this.$store.state.clientOverview.clientData.id;

                    let response;
                    if(whichSave == 'insurance_card') {
                        response = await this.$api.put(insurance.saveInsuranceDetailsInformation(), {
                            insurance: temp,
                            whichSave
                        });

                    }
                    else if(whichSave == 'insurance_verification' && temp.cardId){
                        response = await this.$api.put(insurance.saveInsuranceDetailsInformation(), {
                            insurance: temp,
                            whichSave
                        });
                    }
                    if (response.data.cardId && response.data.veriId) {
                        if (copyOfInsurancePayer) {
                            this.insurance.insurance_payer_id = copyOfInsurancePayer.payer_id;
                        }
                        this.$emit('assignId', this.insurance, response.data.cardId, response.data.veriId);
                    }
                    else if(response.data.cardId && !response.data.veriId) {
                        if (copyOfInsurancePayer) {
                            this.insurance.insurance_payer_id = copyOfInsurancePayer.payer_id;
                        }
                        this.$emit('assignId', this.insurance, response.data.cardId, null);
                    }
                    this.$toasted.success('Saved information successfully.');
                } catch (err) {
                    this.$toasted.error('Could not save information.');
                    // TODO: add snackbar to display other errors to user
                    console.error(err);
                }
            },
            cancelButtonClick() {
                this.resetFields();
                this.isEditMode = false;
            },
            tempSaveFields() {
                this.tempInsuranceDetailsAndVerifi = JSON.parse(JSON.stringify(this.insuranceDetailsAndVerifi));
            },
            resetFields() {
                this.tempInsuranceDetailsAndVerifi = JSON.parse(JSON.stringify(this.tempInsuranceDetailsAndVerifi));
            },
            checkIfVerificationFieldsExist() {
                let fields = {
                    service_code: this.insurance.service_code,
                    name_of_rep: this.insurance.name_of_rep,
                    ref_num_for_call: this.insurance.ref_num_for_call,
                    policy_active: this.insurance.policy_active,
                    deductible_met: this.insurance.deductible_met,
                    is_there_deductible: this.insurance.is_there_deductible,
                    deductible_remain: this.insurance.deductible_remain,
                    visits_allowed: this.insurance.visits_allowed,
                    visits_used: this.insurance.visits_used,
                    visits_remain: this.insurance.visits_remain,
                    co_pay: this.insurance.co_pay,
                    co_insurance: this.insurance.co_insurance,
                    is_provide_in_network: this.insurance.is_provide_in_network,
                    out_of_network_benefits: this.insurance.out_of_network_benefits,
                    preauth_require: this.insurance.preauth_require,
                    auth_num: this.insurance.auth_num,
                    start_date_auth: this.insurance.start_date_auth,
                    end_date_auth: this.insurance.end_date_auth,
                    ref_required: this.insurance.ref_required,
                    service_or_treatment_exclude: this.insurance.service_or_treatment_exclude,
                    limit_exclude_docum_requirement: this.insurance.limit_exclude_docum_requirement,
                    notes: this.insurance.notes,
                    start_date_policy: this.insurance.start_date_policy,
                    end_date_policy: this.insurance.end_date_policy
                };

                for (const key in fields) {
                    if (Object.hasOwnProperty.call(fields, key)) {
                        const element = fields[key];
                        //An element with a valid value was found
                        if (element == null || element == '' || typeof element == 'undefined') {
                            continue;
                        } else {
                            this.showVerificationFields = true;
                        }
                    }
                }
                return;
            },
            async verify_card_check_eligibilities() {
                const payload = { id: this.insurance.cardId };
                const res = await this.$api.post(`/insurance/verify_eligibility`, payload);

                // if it's good, then this.insuranceDetailsAndVerifi = the result
                // refresh the verifications
            }
        },
        async created() {
            await this.get_insurance_payers();
            await this.get_insurance_payers_vob();

            let dob_insured_format = this.insurance.dob_insured;
            if (!dob_insured_format && this.$store.state?.clientOverview?.clientData?.dob) {

                dob_insured_format = (dayjs(this.$store.state.clientOverview.clientData.dob).add(12, 'h').format('YYYY-MM-DD'));
            }
            this.insuranceDetailsAndVerifi = {
                // insurance_payer

                insurance_type: this.insurance.insurance_type ?? '',
                plan_type: this.insurance.plan_type ?? '',
                member_id: this.insurance.member_id ?? '',

                group_id: this.insurance.group_id ?? '',
                name_insured: this.insurance.name_insured ?? '',
                relationship: this.insurance.relationship ?? '',

                dob_insured: dob_insured_format ? dayjs(dob_insured_format).format('YYYY-MM-DD') : '',
                ins_num: this.insurance.ins_num ?? '',
                group_num: this.insurance.group_num ?? '',

                service_code: this.insurance.service_code ?? '',
                name_of_rep: this.insurance.name_of_rep ?? '',
                ref_num_for_call: this.insurance.ref_num_for_call ?? '',
                policy_active: this.insurance.policy_active ?? 1,
                deductible_met: this.insurance.deductible_met ?? 0,
                is_there_deductible: this.insurance.is_there_deductible ?? 0,
                deductible_remain: this.insurance.deductible_remain ?? '',
                visits_allowed: this.insurance.visits_allowed ?? '',
                visits_used: this.insurance.visits_used ?? '',
                visits_remain: this.insurance.visits_remain ?? '',
                co_pay: this.insurance.co_pay ?? '',
                co_insurance: this.insurance.co_insurance ?? '',
                is_provide_in_network: this.insurance.is_provide_in_network ?? 0,
                out_of_network_benefits: this.insurance.out_of_network_benefits ?? 0,
                preauth_require: this.insurance.preauth_require ?? '',
                auth_num: this.insurance.auth_num ?? '',
                start_date_auth: this.insurance.start_date_auth ? dayjs(this.insurance.start_date_auth).format('YYYY-MM-DD') : '',
                end_date_auth: this.insurance.end_date_auth ? dayjs(this.insurance.end_date_auth).format('YYYY-MM-DD') : '',
                ref_required: this.insurance.ref_required ?? '',
                service_or_treatment_exclude: this.insurance.service_or_treatment_exclude ?? '',
                limit_exclude_docum_requirement: this.insurance.limit_exclude_docum_requirement ?? 0,
                notes: this.insurance.notes ?? '',
                start_date_policy:
                    this.insurance.start_date_policy ? dayjs(this.insurance.start_date_policy).format('YYYY-MM-DD') : '',
                end_date_policy:
                    this.insurance.end_date_policy ? dayjs(this.insurance.end_date_policy).format('YYYY-MM-DD') : '',
                insurance_payer: this.insurance_payers_list.some(
                    (element) => element.id == this.insurance?.insurance_payers_id
                )
                    ? this.insurance_payers_list.find((element) => element.id == this.insurance?.insurance_payers_id)
                    : {},
                insurance_vob: this.insurance_payers_vob_list.some(
                    (element) => element.id == this.insurance?.card_insurance_payers_vob_id
                )
                    ? this.insurance_payers_vob_list.find((element) => element.id == this.insurance?.card_insurance_payers_vob_id)
                    : {}
            };
            this.emitCurrentlySelectedPayer(this.insuranceDetailsAndVerifi.insurance_payer);

            this.date_veri_completed = this.insurance.dayt_mod ? dayjs(this.insurance.dayt_mod).format('YYYY-MM-DD') : '';

            this.checkIfVerificationFieldsExist();
            await this.$forceUpdate();

        },

    };

    /*
    ins_num
    group_num
    service_code
    name_of_rep
    ref_num_for_call
    policy_active
    deductible_met
    is_there_deductible
    deductible_remain
    visits_allowed
    visits_used
    visits_remain
    co_pay
    co_insurance
    is_provide_in_network
    out_of_network_benefits
    preauth_require
    auth_num
    start_date_auth
    end_date_auth
    ref_required
    service_or_treatment_exclude
    limit_exclude_docum_requirement
    notes
    start_date_policy
    end_date_policy


    ins_num: this.insurance.ins_num ?? ''
    group_num: this.insurance.group_num ?? ''
    service_code: this.insurance.service_code ?? ''
    name_of_rep: this.insurance.name_of_rep ?? ''
    ref_num_for_call: this.insurance.ref_num_for_call ?? ''
    policy_active: this.insurance.policy_active ?? ''
    deductible_met: this.insurance.deductible_met ?? ''
    is_there_deductible: this.insurance.is_there_deductible ?? ''
    deductible_remain: this.insurance.deductible_remain ?? ''
    visits_allowed: this.insurance.visits_allowed ?? ''
    visits_used: this.insurance.visits_used ?? ''
    visits_remain: this.insurance.visits_remain ?? ''
    co_pay: this.insurance.co_pay ?? ''
    co_insurance: this.insurance.co_insurance ?? ''
    is_provide_in_network: this.insurance.is_provide_in_network ?? ''
    out_of_network_benefits: this.insurance.out_of_network_benefits ?? ''
    preauth_require: this.insurance.preauth_require ?? ''
    auth_num: this.insurance.auth_num ?? ''
    start_date_auth: this.insurance.start_date_auth ?? ''
    end_date_auth: this.insurance.end_date_auth ?? ''
    ref_required: this.insurance.ref_required ?? ''
    service_or_treatment_exclude: this.insurance.service_or_treatment_exclude ?? ''
    limit_exclude_docum_requirement: this.insurance.limit_exclude_docum_requirement ?? ''
    notes: this.insurance.notes ?? ''
    start_date_policy: this.insurance.start_date_policy ?? ''
    end_date_policy: this.insurance.end_date_policy ?? ''
    */
</script>
