<template>
    <NoButtonModal :open='open' @close='cancel' id='the_new_client_dropdown_modal'>
        <button class='close-corner-x' type='button' @click.prevent='cancel'>
            <span class='material-icons-outlined dark-text'>close</span>
        </button>
        <div class='flex'>
            <div class='right-20'>
                <span class='material-icons-outlined red-text large-icon'>error_outline</span>
            </div>
            <div>
                <h2 class='bottom-5'>Warning</h2>
                <p>Any data in the fields below will be cleared. You will still be able to manually add information
                    after running the verification. Would you still like to proceed and run the verification?</p>
            </div>
        </div>
        <div class='fullwidth align-right'>
            <button class='secondary' type='button' @click.prevent='cancel'>Cancel</button>
            <button class='primary' @click='proceed' type='button'>Proceed</button>
        </div>
    </NoButtonModal>
</template>

<script>
    import NoButtonModal from '@/components/general/modals/NoButtonModal';

    export default {
        name: 'RunVerificationModal',
        components: { NoButtonModal },
        props: {
            open: {
                type: Boolean,
                required: true
            }
        },
        data() {
            return {};
        },
        methods: {
            proceed() {
                this.resetForm('proceed');
            },
            cancel() {
                this.resetForm('cancel');
            },
            resetForm(action) {
                this.$emit('close', action);
            }

        },
        async created() {

        }

    };
</script>

<style scoped>

</style>