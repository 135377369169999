<template>
    <!-- Primary use of this component is for going to different pages -->
    <!-- But it's emit action could theoretically allow you to utilize it for going to different sections on a single page via anchor -->
    <div class="member-profile-menu">
        <div v-for="(page, pageKey) in pages" :key="pageKey">
            <button @click="doAction(page)" :class="getClass(page.name)">
                <div class='member-profile-menu-icon'>
                    <span v-if="page.name === 'ClientOverview'" class="material-icons-outlined">grid_view</span>
                    <span v-if="page.name === 'ClientDemographics'" class="material-icons-outlined">group</span>
                    <span v-if="page.name === 'ClientClinical'" class="material-symbols-outlined">stethoscope</span>
                    <span v-if="page.name === 'ClientDocuments'" class="material-icons-outlined">
                        description
                    </span>
                    <span v-if="page.name === 'ClientPhotos'" class="material-icons-outlined">portrait</span>
                    <span v-if="page.name === 'Link Sharing'" class="material-icons-outlined">link</span>
                    <span v-if="page.name === 'Vitals'" class="material-symbols-outlined">
                        ecg_heart</span>
                </div>
                <div :class="page.name == $route.name ? 'member-profile-menu-label' : 'member-profile-menu-label'">{{
                    page.label }}
                </div>
            </button>
        </div>

    </div>
</template>

<script>
import { mapState } from 'vuex';
export default {
    props: {
        pages: {

        }
    },
    methods: {
        async doAction(page) {
            this.$emit('action', page)
        }, get_icon_label(page) {
            return `@/assets/px/${page.icon_label}.png`
        },
        getClass(pageName) {
            let classes = 'member-profile-menu-button'
            if (this.sideBartoggleValue) {
                classes += ' member-profile-menu-button-shrinked'
            }
            if (pageName == this.$route.name) {
                classes += ' member-profile-menu-active'
            }

            return classes
        }
    },
    computed: {
        ...mapState({
            sideBartoggleValue: state => state.sideBartoggleValue
        })
    }
}
</script>

<style></style>