<template>
    <div class="single-setting office flex center">
        <span class="material-icons-outlined right-5">holiday_village</span>
        <div class="flex-1" v-if='office_locations.length > 1'>
            <Dropdown
                :value="primaryOfficeId"
                @input="updatePrimaryOffice"
                id="primaryOffice"
                name="primaryOffice"
                label=""
                :options="office_locations"
            />
        </div>
        <div v-else>
            {{ officeName }}
        </div>
    </div>
</template>

<script>
    import { offices, users } from '@/util/apiRequests';

    export default {
        name: 'OfficeDropdown',
        data() {
            return {
                primaryOfficeId: 0,
                office_locations: []
            };
        },
        computed: {
            officeName() {
                if (this.office_locations.length > 0) {
                    return this.office_locations[0].text;
                } else {
                    return '';
                }
            }
        },
        methods: {
            async init() {
                await Promise.all([
                    this.get_offices()
                ]);
            },
            async get_offices() {
                try {
                    const res = await this.$api.get(offices.getOffices());
                    this.office_locations = res.data.map((office) => ({ text: office.office_name, value: office.id }));
                    const resU = await this.$api.get(users.getHeaderDetails(this.$store.state.user.id));
                    this.primaryOfficeId = resU.data.primaryOfficeId;
                } catch (err) {
                    this.$toasted.error('Could not get info');
                }
            },
            async update(name, field, value) {
                try {
                    await this.$api.put(users.updateUser(this.userId), { [field]: value });
                    this.$toasted.success(`Successfully updated ${name}.`);
                    return true;
                } catch (err) {
                    //this.$toasted.error(`Failed to update ${name}. Please try again later.`);
                    return false;
                }
            },
            async updatePrimaryOffice(newState) {
                const updateSuccess = await this.update('Primary Office', 'office_id', newState);
                if (updateSuccess) {
                    this.primaryOfficeId = newState;
                }
            },
        },
        async created() {
            await this.init();
        }
    };
</script>

<style scoped>

</style>